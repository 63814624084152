import { TrackingConstant } from 'client/tracking/constant';
import { EventToolbox } from 'client/utils/event-toolbox';
import { getSquishVinNoMmyPixelValue } from 'site-modules/shared/utils/appraisal/tracking';

export const fireSquishVinTracking = (
  { isSquishVinValid, squishStyles, hasError, status, ignoreStatus = false },
  vin,
  creativeId
) => {
  const trackingData = {
    event_type: TrackingConstant.EVENT_TYPE_ACTION_PROGRESS,
    event_data: {
      action_name: TrackingConstant.ACTION_APPRAISE_VEHICLE,
      subaction_name: TrackingConstant.SUBACTION_SQUISHVIN_DECODE,
      action_category: TrackingConstant.SYSTEM_ACTION_CATEGORY,
      action_cause: TrackingConstant.ACTION_CAUSE_USER_INPUT,
      creative_id: creativeId,
      value: TrackingConstant.MMY_NOT_FOUND,
    },
  };

  if (squishStyles.length) {
    EventToolbox.fireTrackAction({
      ...trackingData,
      event_data: {
        ...trackingData.event_data,
        value: squishStyles.length > 1 ? TrackingConstant.MMY_MANY_FOUND : TrackingConstant.MMY_ONE_FOUND,
      },
    });
    trackingData.event_data.value = isSquishVinValid ? TrackingConstant.MMY_MATCH : TrackingConstant.MMY_MISMATCH;
    trackingData.event_data.subaction_name = TrackingConstant.SUBACTION_SQUISHVIN_VALIDATION;
  }
  EventToolbox.fireTrackAction(trackingData);

  const subValue = trackingData.event_data.value;
  if ([TrackingConstant.MMY_NOT_FOUND, TrackingConstant.MMY_MISMATCH].includes(subValue)) {
    const valueSubactionName = trackingData.event_data.subaction_name;
    const isSquishVinNoMmy =
      valueSubactionName === TrackingConstant.SUBACTION_SQUISHVIN_DECODE && subValue === TrackingConstant.MMY_NOT_FOUND;
    const value =
      isSquishVinNoMmy && !ignoreStatus
        ? getSquishVinNoMmyPixelValue(vin, { hasError, status })
        : `${vin}_${valueSubactionName}_${subValue}`;
    EventToolbox.fireTrackAction({
      ...trackingData,
      event_data: {
        ...trackingData.event_data,
        subaction_name: TrackingConstant.SUBACTION_VIN_ERROR,
        value,
      },
    });
  }
};
