import React, { useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Button } from 'reactstrap';
import { Spinner } from 'site-modules/shared/components/spinner/spinner';
import { useAppraisalTabsContext } from 'site-modules/shared/components/appraisal/appraisal-tabs/appraisal-tabs-context';
import { ENTER_KEY_VALUE } from 'site-modules/shared/constants/key-values';

export function AppraisalTabsSubmitButton({
  onSubmit,
  isDisabled,
  isSubmitting,
  ctaText,
  containerClassName,
  buttonClassName,
  isActiveTab,
  ...restProps
}) {
  const { ctaBtnColor } = useAppraisalTabsContext();

  const onKeyPress = useCallback(
    event => {
      const { key } = event;

      if (key === ENTER_KEY_VALUE && isActiveTab) {
        onSubmit(event);
      }
    },
    [isActiveTab, onSubmit]
  );

  useEffect(() => {
    window.addEventListener('keydown', onKeyPress);

    return () => {
      window.removeEventListener('keydown', onKeyPress);
    };
  }, [onKeyPress]);

  return (
    <div className={classnames('appraisal-tabs-submit-btn-container', containerClassName)}>
      <Button
        color={ctaBtnColor}
        className={classnames(
          'appraisal-tabs-submit-btn text-transform-none cta-btn d-flex align-items-center justify-content-center',
          buttonClassName
        )}
        onClick={onSubmit}
        disabled={isDisabled}
        type="submit"
        {...restProps}
      >
        {isSubmitting && (
          <span className="pe-0_5" data-testid="appraise-btn-spinner">
            <Spinner size={14} thickness={1} color="white" />
          </span>
        )}
        <span className="size-16">{ctaText}</span>
      </Button>
    </div>
  );
}

AppraisalTabsSubmitButton.propTypes = {
  ctaText: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool,
  isDisabled: PropTypes.bool,
  containerClassName: PropTypes.string,
  buttonClassName: PropTypes.string,
  isActiveTab: PropTypes.bool,
};

AppraisalTabsSubmitButton.defaultProps = {
  ctaText: 'Appraise My Car',
  isSubmitting: false,
  isDisabled: false,
  containerClassName: '',
  buttonClassName: '',
  isActiveTab: false,
};
